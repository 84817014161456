import React from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';

import { VIEWPORTS, BACKGROUND_MAX_WIDTHS } from '~/shared/constants';
import SinglestepForm from '~/domains/sales-leads/components/FormSection';
import MultistepForm from '~/domains/sales-leads/components/MultistepForm';
import BackgroundImage from '~/shared/components/BackgroundImage';
import dataSelector from '~/shared/util/data-selector';
// eslint-disable-next-line max-len
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import useViewportName from '~/shared/hooks/use-viewport-name';
import { BACKGROUNDS } from '~/shared/constants/sections';

const DATA_SELECTOR = 'embedded_form';

const TABLET_MARGIN = '-76px';
const TABLET_PADDING = '20px';
const MOBILE_MARGIN = '-50px;';
const MOBILE_PADDING = '38px';

const FORM_TYPES = {
  SINGLE_STEP: 'form',
  MULTI_STEP: 'multiStepForm',
};
const FORM_COMPONENTS = {
  [FORM_TYPES.SINGLE_STEP]: SinglestepForm,
  [FORM_TYPES.MULTI_STEP]: MultistepForm,
};

const containerStyles = ({
  theme,
  formType,
  background,
  noTopMargin,
}: {
  theme?: Theme;
  formType: string;
  background: string;
  noTopMargin: boolean;
}) => css`
  position: relative;
  overflow: hidden;
  background-color: ${BACKGROUNDS.WHITE === background
    ? 'var(--cui-bg-normal)'
    : 'var(--cui-bg-subtle)'};

  border-radius: ${theme.borderRadius.mega};
  margin-top: ${!noTopMargin ? theme.spacings.exa : 0};
  margin-bottom: ${theme.spacings.exa};

  ${formType === FORM_TYPES.SINGLE_STEP &&
  `
    padding-top: ${theme.spacings.tera};
    padding-bottom: ${theme.spacings.tera};
  `}

  ${theme.mq.mega} {
    margin-left: -${theme.spacings.tera};
    margin-right: -${theme.spacings.tera};
  }

  ${theme.mq.kiloToMega} {
    margin-left: ${TABLET_MARGIN};
    margin-right: ${TABLET_MARGIN};
    padding-left: ${TABLET_PADDING};
    padding-right: ${TABLET_PADDING};
  }

  ${theme.mq.untilKilo} {
    margin-left: ${MOBILE_MARGIN};
    margin-right: ${MOBILE_MARGIN};
    padding-left: ${MOBILE_PADDING};
    padding-right: ${MOBILE_PADDING};
    border-radius: 0;
  }
`;
const Container = styled('div')(containerStyles);

const formWrapperStyles = () => css`
  position: relative;
  z-index: 3;
`;
const FormWrapper = styled('div')(formWrapperStyles);

function getForm({ contentType, ...props }) {
  const FormComponent = FORM_COMPONENTS[contentType];

  return FormComponent ? (
    <FormComponent
      data-selector={dataSelector('form', DATA_SELECTOR)}
      {...props}
    />
  ) : null;
}

export interface EmbeddedFormProps {
  formRef?: { contentType: string };
  backgroundImageDesktop: Record<string, any>;
  backgroundImageTablet: Record<string, any>;
  backgroundImageMobile: Record<string, any>;
  noTopMargin: boolean;
  fullWidthForm: boolean;
}

/**
 * Embed form or multistep form into rich text.
 */
function EmbeddedForm({
  formRef: originalFormRef = { contentType: '' },
  backgroundImageDesktop,
  backgroundImageTablet,
  backgroundImageMobile,
  noTopMargin = false,
  fullWidthForm = false,
}: EmbeddedFormProps) {
  const viewportName = useViewportName();
  const { experiments } = useOptimizelyData();
  const formRef = getVariationComponent(originalFormRef, experiments);

  const viewports = {
    [VIEWPORTS.MOBILE]: getVariationComponent(
      backgroundImageMobile,
      experiments,
    ),
    [VIEWPORTS.DESKTOP]: getVariationComponent(
      backgroundImageDesktop,
      experiments,
    ),
    [VIEWPORTS.TABLET]: getVariationComponent(
      backgroundImageTablet,
      experiments,
    ),
  };
  const viewportBackgroundImage = viewports[viewportName];
  const backgroundImageWidth = BACKGROUND_MAX_WIDTHS[viewportName];
  const backgroundImageProps = {
    ...viewportBackgroundImage,
    width: backgroundImageWidth,
  };
  const form = getForm({ ...formRef, fullWidthForm });

  if (isEmpty(form)) {
    return null;
  }

  return (
    <Container
      formType={formRef.contentType}
      background={formRef.background.toLowerCase()}
      noTopMargin={noTopMargin}
      data-selector={dataSelector('container', DATA_SELECTOR)}
    >
      <BackgroundImage {...backgroundImageProps} />
      <FormWrapper>{form}</FormWrapper>
    </Container>
  );
}

/**
 * @component
 */
export default EmbeddedForm;
